import axios from 'axios'

export default {
  state: {
    sobranie: [{'id': 0,'title': '', 'text': '', 'date': ''}],
    otchet: [{'id': 0,'title': '', 'text': '', 'date': ''}],
    dividend: [{'id': 0,'title': '', 'text': '', 'date': ''}]
  },

  mutations: {
    mut_sobranie(state, payload) {
      state.sobranie = []
      state.sobranie = payload
    },
    
    mut_otchet(state, payload) {
      state.otchet = []
      state.otchet = payload
    },

    mut_dividend(state, payload) {
      state.dividend = []
      state.dividend = payload
    },
  },

  actions: {
    async select_category({dispatch, commit, rootState}, payload) {
      await dispatch('host', null, {root: true})
      let response = await axios({
        url: `${rootState.host}/categoryAkcioner`,
        method: 'POST',
        data: payload
      })
      let result = await response.data
      commit(`mut_${payload.cat.split('_')[0]}`, result)
    },
    async akcioner ({dispatch, rootState}, payload) {
      await dispatch('host', null, {root: true})
      let response = await axios({
        url: `${rootState.host}/akcioner`,
        method: 'POST',
        data: payload
      })
      let result = await response.data
      /* dispatch('select_category', payload) */
      return result
    }
  },

  getters: {
    getSobranie: state => state.sobranie,
    getOtchet: state => state.otchet,
    getDividend: state => state.dividend
  }
}
