<template>
  <div class="site-wrapper">
    <q-layout>

      <q-header>
        <TopMenu/>
        <switchLang class="switchLang"/>
      </q-header>

      <q-page-container>
        <router-view />
      </q-page-container>

      <q-footer class="layout-footer">
        <Footer/>
      </q-footer>

      <div class="to-top-wrapper">
        <div class="ttw">
          <transition name="bounce">
            <q-btn
              v-if="showtop"
              class="to-top"
              round
              size="18px"
              @click="toTop()"
              icon="mdi-chevron-up"
              text-color="white"
            />
          </transition>
        </div>
      </div>

      </q-layout>
  </div>
  
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import Footer from '@/components/Footer.vue'
import BtnMail from '@/components/BtnMail.vue'
import switchLang from '@/components/switchLang.vue'
export default {
  components: { TopMenu, Footer, BtnMail, switchLang },
  data: () => ({
    showtop: false
  }),
  methods: {
    toTop: () => {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
  },
  created() {
    window.onscroll = () => {
      window.scrollY > 570 ? this.showtop = true : this.showtop = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .layout-footer {
    background-color:#232323;
  }
  .to-top-wrapper {
    position: relative;
    .ttw {
      position: absolute;
      right: 15px;
      width: 100px;
      .to-top {
        position: fixed;
        bottom: 10px;
        z-index: 3000;
        outline: none;
        background: #f27937;
      }
    }
  }
  @media only screen and (max-width: 1253px) {
    .switchLang {
      display: none;
    }
  }
</style>
