import axios from 'axios'
export default {
  state: {
    price: [{'id': 0, 't': '', 'n': '', 'c': ''}],
    priceDate: {}
  },

  mutations: {

    mutPrice(state, payload) {
      state.price = []
      state.price = payload
    },
    mutPriceDate(state, payload) {
      state.priceDate = {}
      state.priceDate = payload
    }

  },

  actions: {

    async price({dispatch, commit, rootState}) {
      await dispatch('host', null, {root: true})
      let response = await axios({
        url: `${rootState.host}/price`,
        method: 'POST',
      })
      let result = await response.data
      commit('mutPrice', result)
    },

    async uploadPrice({dispatch, rootState}, payload) {
      await dispatch('host', null, {root: true})
      let formData = new FormData()
      formData.append('file', payload.file)
      let response = await axios({
        url: `${rootState.host}/insertPrice`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
      })
      let result = await response
      dispatch('priceDate')
      return result.data
    },

    async priceDate({dispatch, commit, rootState}) {
      await dispatch('host', null, {root: true})
      let response = await axios({
        url: `${rootState.host}/priceDate`,
        method: 'POST'
      })
      let result = await response.data
      commit('mutPriceDate', result)
    }

  },

  getters: {
    getPrice: state => state.price,
    getPriceDate: state => state.priceDate
  }
}