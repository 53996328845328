
import './styles/quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import '@quasar/extras/eva-icons/eva-icons.css'
import ru from 'quasar/lang/ru'
import be from 'quasar/lang/be'
import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  lang: localStorage.getItem('lang') == 'ru' ? ru : localStorage.getItem('lang') == 'be' ? be : ru,
  config: {},
  plugins: { Notify },
  extras: [
    'material-icons',
    'mdi-v7',
    'ionicons-v4',
    'eva-icons',
    'fontawesome-v6',
    'themify',
    'line-awesome',
    'bootstrap-icons'
  ]
}