<template>

  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="#f27937"
        fab
        dark
        class="btn"
        v-bind="attrs"
        v-on="on"
        :small=small
      >
        <v-icon class="icon">mdi-email</v-icon>
      </v-btn>
    </template>
    <span>обратная связь</span>
  </v-tooltip>
  
</template>

<script>
export default {
  data:  () => ({
    small: false
  }),
  mounted() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.small = true
      } else {
        this.small = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn {
    position: fixed;
    z-index: 3;
    bottom: 10px;
    right: 10px;
  }
  .icon {
    animation: 1.5s ease 0s normal none 1 running blink;
    animation-iteration-count: infinite;
    /* animation: 1200ms ease 0s normal none 1 running blink;
    animation-iteration-count: infinite; */
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>