import { createStore } from 'vuex'
import axios from 'axios'
import price from './price'
import auth from './auth'
import akcioner from './akcioner'
import infoview from './infoview'
import buhotchet from './buhotchet'

export default createStore({
  state: {
    host: '',
    raskinfo: {},
    rukinfo: [],
    info: [],
    content: {'video': []}
  },
  mutations: {

    mutHost(state, payload) {
      state.host = payload.host
    },

    mutContent(state, payload) {
      state.content = {}
      state.content = payload
    },

    mutRaskInfo(state, payload) {
      state.raskinfo = {}
      state.raskinfo = payload
    },

    mutInfo(state, payload) {
      state.info = []
      state.info = payload
    },

    mutRukInfo(state, payload) {
      state.rukinfo = []
      state.rukinfo = payload
    },

  },
  actions: {

    async host({commit}) {
      let response = await axios({
        method: 'GET',
        url: './json/host.json'
      })
      let result = await response
      commit('mutHost', result.data)
    },

    async content({commit}) {
      let response = await axios({
        method: 'GET',
        url: `./json/content.json?` + Date.now()
      })
      let result = await response
      commit('mutContent', result.data)
    },

    async raskInfo({commit}) {
      let response = await axios({
        method: 'GET',
        url: './json/raskinfo.json?' + Date.now()
      })
      let result = await response
      commit('mutRaskInfo', result.data) 
    },

    async info({commit}) {
      let response = await axios({
        method: 'GET',
        url: './json/info.json?' + Date.now()
      })
      let result = await response
      commit('mutInfo', result.data)
    },

    async rukinfo({commit}) {
      let response = await axios({
        method: 'GET',
        url: './json/rukinfo.json?' + Date.now()
      })
      let result = await response
      commit('mutRukInfo', result.data)
    },

  },
  getters: {
    getContent: state => state.content,
    getRaskInfo: state => state.raskinfo,
    getRukInfo: state => state.rukinfo,
    getInfo: state => state.info,
  },
  modules: {
    auth, price, akcioner, infoview, buhotchet
  }
})
