<template>
  <q-layout class="bg-grey-9 text-white admin-layout">
    <q-header elevated class="bg-grey-6">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="toggleLeftDrawer"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title>
          ОАО «Западно - Двинский МРС»
        </q-toolbar-title>
        <q-btn
          class="btn"
          label="Выйти"
          dense
          @click="log_out"
        />
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      class="bg-grey-8"
    >
      <q-list dark>
        <q-item-label header>Выберите раздел</q-item-label>
        <q-item
          active-class="my-menu-link"
          v-for="(item, i) in links"
          :key="i"
          clickable
          v-ripple
          :to="item.url">
          <q-item-section avatar>
            <q-icon :name="item.ico" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ item.title }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
    <q-footer class="layout-footer">
      2017 - {{ new Date().getFullYear() }} <q-icon name="mdi-copyright"/> <span>ОАО «Западно - Двинский МРС»</span>
    </q-footer>
  </q-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  export default {
    props: {
      
    },
    data: () => ({
      leftDrawerOpen: false,
      date: new Date(),
      interval: null,
      links: [
        /* {title: 'Главная', url: '/main'}, */
        {title: 'Прайс - лист', url: '/admin_price', ico: 'mdi-format-list-numbered'},
        {title: 'Для акционеров', url: '/admin_akcioner', ico: 'mdi-account-group'},
        {title: 'Информация', url: '/admin_infoview', ico: 'mdi-book-information-variant'},
        {title: 'Раскрытие информации', url: '/admin_info', ico: 'mdi-folder-information'}
      ],
    }),
    methods: {
      ...mapActions(['logout', 'priceDate']),
      ...mapGetters(['isLoggedIn', 'getUser']),
      toggleLeftDrawer () {
        this.leftDrawerOpen = !this.leftDrawerOpen
      },
      async log_out() {
        await this.logout()
        if(this.isLoggedIn() == false) {
          this.$router.push({ path: '/admin_zapdvin' })
        }
      },
    },
    created () {
      
    },
    async mounted () {
      await this.priceDate()
      this.interval = setInterval(() => {
        this.date = new Date()
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval)
    }
  }
</script>

<style lang="scss" scoped>
  .btn {
    padding: 0 15px;
    background-color: #FF4500;
  }
  .layout-footer {
    background: #9e9e9e;
    text-align: center;
    font-size: 15px;
    padding: 5px 0px;
    color: #232323;
    font-weight: bold;
  }
  .admin-layout {
    text-align: left;
  }
  .my-menu-link {
    color: white;
    background: #f27937;
  }
</style>