import axios from 'axios'

export default {
  state: {
    buhotchet: []
  },

  mutations: {
    mutBuhOtchet(state, payload) {
      state.buhotchet = []
      state.buhotchet = payload
    }
  },

  actions: {
    async get_buhotchet({dispatch, commit, rootState}) {
      await dispatch('host', null, {root: true})
      let response = await axios({
        url: `${rootState.host}/get_buhotchet`,
        method: 'POST'
      })
      let result = await response.data
      commit('mutBuhOtchet', result)
    }
  },

  getters: {
    getBuhOtchet: state => state.buhotchet
  }
}
