<template>
  <div>
    <div class="home-layout-footer">
      <div class="items1">
        <div class="item">
          <q-img src="img/logo3.webp"  style="max-width:60px"/>
          <p v-html="$tm('orgSplit')"></p>
        </div>
        <div class="item"><p>{{ $t('items.item15') }}<br>{{ $t('items.item16') }}<br>{{ $t('items.item17') }}</p></div>
        <div class="item">
          <q-icon name="mdi-phone"/>  <a style="color: #ffffff;" :href="`tel:${getInfo.tel}`">{{getInfo.tel}}</a>
          <br>
          <q-icon name="mdi-email"/>  <a :href="`mailto:${getInfo.email}`">{{getInfo.email}}</a>
          <br>
          <q-icon name="mdi-map-marker-radius"/>  {{ $t('contacts.item1') }}
        </div>
      </div>
      <div class="border"></div>
      <div class="items2">
        2017 - {{ new Date().getFullYear() }} <q-icon name="mdi-copyright"/> <span>{{ $t('org') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  computed: {
    ...mapGetters(['getInfo'])
  },
}
</script>

<style lang="scss" scoped>
  .divider {
    margin-top: 210px;
    height: 1px;
  }
  .home-layout-footer {
    background-color: rgb(35, 35, 35);
    border-color: rgb(35, 35, 35);
    .items1 {
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
      align-content: center;
      justify-content: center;
      color: #ffffff;
      margin: 25px 10px 10px;
      .item {
        padding: 10px;
        &:nth-child(1) {
          margin: 0 auto;
          min-width: 250px;
          .q-img {
            float: left;
          }
          p {
            text-align: center;
            margin-top: 10px;
          }
        }
        &:nth-child(2) {
          text-align: left;
          max-width: 250px;
          margin: 0 auto;
        }
        &:nth-child(3) {
          text-align: left;
          max-width: 335px;
          margin: 0 auto;
          span {
            color: red;
          }
          a {
            text-decoration: none;
            color: #f27937;
          }
        }
      }
    }
    .border {
      border-top: 1px solid #3d3d3d;
    }
    .items2 {
      color: #ffffff;
      margin: 15px 10px;
    }
  }
  @media only screen and (max-width: 945px) {
    .items1 {
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
    }
    .item {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        margin: 0;
      }
    }
  }
</style>