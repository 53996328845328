import axios from 'axios'

export default {
  state: {
    infoview: [{'id': 0,'title': '', 'text': '', 'date': ''}]
  },

  mutations: {
    mutInfoView(state, payload) {
      state.infoview = []
      state.infoview = payload
    }
  },

  actions: {
    async get_infoview({dispatch, commit, rootState}, payload) {
      await dispatch('host', null, {root: true})
      let response = await axios({
        url: `${rootState.host}/get_infoview`,
        method: 'POST',
        data: payload
      })
      let result = await response.data
      commit('mutInfoView', result)
    },
    
    async infoview({dispatch, rootState}, payload) {
      await dispatch('host', null, {root: true})
      let response = await axios({
        url: `${rootState.host}/infoview`,
        method: 'POST',
        data: payload
      })
      let result = await response.data
      // dispatch('get_infoview', {cat: 'infoview'})
      return result
    }
  },

  getters: {
    getInfoView: state => state.infoview
  }
}
