<template>

  <component 
    :is="layout"
  >
    <router-view/>
  </component>
  
</template>

<script>

import HomeLayout from '@/layout/HomeLayout.vue'
import AdminLayout from '@/layout/AdminLayout.vue'
import {mapActions} from 'vuex'
export default {
  components: {
    HomeLayout, AdminLayout
  },
  computed: {
    layout() {
      return this.$route.meta.layout
    }
  },
  methods: {
    ...mapActions(['info'])
  },
  async mounted() {
    await this.info()
  }
}
</script>

<style lang="scss">
  @import url('./assets/css/main.scss');
  /* #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  } */
</style>
