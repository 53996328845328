<template>
  <div class="lang">
    <q-btn-dropdown
      style="background: #232323;border-radius: 0px;font-size: 11px;"
      dense 
      icon="mdi-web" 
      :label="`${$t('items.item20')} ( ${locale} )`"
    >
      <q-list style="background: #232323;color: #ffffff;border-radius: 0px;">
        <q-item clickable v-close-popup @click="onItemClick('be')">
          <q-item-section>
            <q-item-label>{{ $t('items.item22') }}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup @click="onItemClick('ru')">
          <q-item-section>
            <q-item-label>{{ $t('items.item21') }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      
    </q-btn-dropdown>
  </div>
</template>

<script>
  import { watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  export default {
    data: () => ({
      
    }),
    methods: {
      onItemClick(val) {
        localStorage.setItem('lang', val)
        this.locale != val ? this.locale = val : this.locale = localStorage.getItem('lang')
      }
    },
    setup() {
      const { t, locale } = useI18n()
      return { locale }
    }
  }
</script>

<style lang="scss" scoped>
  .lang {
    user-select: none;
    cursor: pointer;
    color: #ffffff;
    position: absolute;
    right: 7px;
    top: 57px;
  }
</style>